import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  CardBody,
  CardBodyText,
  CardHeader,
  DiscountBadge,
  MainContainer,
  RewardsCard,
  RewardsContainer,
  RewardsHeader,
  DollarBadge,
  StarBadge,
  SmallText,
  DollarBadgeB,
} from './styles'
import { dollar, star } from '../../../images/waitlist/rewards'

const Rewards = () => {
  const { t } = useTranslation(['waitlist'])

  return (
    <MainContainer>
      <DollarBadge src={dollar} />
      <StarBadge src={star} />
      <DollarBadgeB src={dollar} />
      <RewardsHeader>{t('rewards')}</RewardsHeader>
      <RewardsContainer>
        <RewardsCard>
          <CardHeader>Top 1.000</CardHeader>
          <CardBody>
            <CardBodyText>{t('earlyAccess')}</CardBodyText>
            <DiscountBadge>15%</DiscountBadge>
            <CardBodyText>{t('feesCollected')}</CardBodyText>
            <CardBodyText>{t('NFTWhitelist')}</CardBodyText>
            <CardBodyText className="cross">{t('OneFree')}</CardBodyText>
          </CardBody>
        </RewardsCard>
        <RewardsCard className="main">
          <CardHeader className="main-header">Top 100*</CardHeader>
          <CardBody>
            <CardBodyText>{t('earlyAccess')}</CardBodyText>
            <DiscountBadge>25%</DiscountBadge>
            <CardBodyText>{t('feesCollected')}</CardBodyText>
            <CardBodyText>{t('NFTWhitelist')}</CardBodyText>
            <CardBodyText>{t('OneFree')}</CardBodyText>
            <SmallText>{t('OneFreeMain')}</SmallText>
          </CardBody>
        </RewardsCard>
        <RewardsCard>
          <CardHeader>Top 10.000</CardHeader>
          <CardBody>
            <CardBodyText>{t('earlyAccess')}</CardBodyText>
            <DiscountBadge>5%</DiscountBadge>
            <CardBodyText>{t('feesCollected')}</CardBodyText>
            <CardBodyText className="cross">{t('NFTWhitelist')}</CardBodyText>
            <CardBodyText className="cross">{t('OneFree')}</CardBodyText>
          </CardBody>
        </RewardsCard>
      </RewardsContainer>
    </MainContainer>
  )
}

export default Rewards
