import styled from 'styled-components'

export const RightCol = styled.div`
  width: 48%;
  display: flex;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const WaitlistContainer = styled.div`
  width: 100%;
  scroll-behavior: smooth;
`

export const WaitlistHeading = styled.div`
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 0.4em;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`
export const Waitlist = styled.ul`
  border-top: 3px solid #000;
  height: 190px;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    height: 35px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`

export const WaitlistEntry = styled.li`
  list-style: none;
  border-bottom: 1px solid #000;
  font-weight: 400;
  font-size: 24px;
  padding: 0.4em 0px;
  padding-left: 2em;
  display: flex;
  gap: 0.8em;
  scroll-behavior: smooth;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    padding-left: 0;
    gap: 0.4em;
  }
`

export const DownVector = styled.img`
  margin-top: 1.4em;
`

export const ScrollBtn = styled.button`
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
`
