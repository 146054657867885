import styled from 'styled-components'

import lines from '../../../images/waitlist/lines.svg'

export const MainContainer = styled.div`
  position: relative;
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  height: 390px;
  background: #f2059f;
  border-radius: 3.2em;
  padding: 4em 8em;
  margin-bottom: 8em;
  background-image: url(${lines});
  background-size: cover;
  margin-bottom: 30em;

  @media only screen and (max-width: 1120px) {
    height: 300px;
  }

  @media only screen and (max-width: 600px) {
    height: 1060px;
    background-size: 90em;
    margin-bottom: 12em;
    padding: 4em 1em;
  }
`

export const PhoneImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 1440px) {
    max-width: 400px;
  }

  @media only screen and (max-width: 1120px) {
    max-width: 350px;
  }

  @media only screen and (max-width: 600px) {
    max-width: 380px;
  }
`

export const LeftCoinGroup = styled.img`
  position: absolute;
  top: -10%;
  left: 0%;

  @media only screen and (max-width: 1440px) {
    max-width: 360px;
    top: 0;
  }

  @media only screen and (max-width: 1120px) {
    max-width: 280px;
    top: 5%;
  }

  @media only screen and (max-width: 600px) {
    max-width: 300px;
    top: 810px;
    left: 35px;
  }
`

export const RightCoinGroup = styled.img`
  position: absolute;
  top: -20%;
  right: 0%;

  @media only screen and (max-width: 1440px) {
    max-width: 360px;
    top: 0;
  }

  @media only screen and (max-width: 1120px) {
    max-width: 280px;
    top: 5%;
  }

  @media only screen and (max-width: 600px) {
    max-width: 310px;
    top: -40px;
    left: 33px;
  }
`
