import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { MainContainer, MainText, Row, UPArrow, UPArrowR } from './style'

import LeftColumn from './LeftColumn/LeftColumn'
import RightColumn from './RightColumn/RightColumn'

import { upArrow } from '../../../images/waitlist/position'
import {
  CampaignLocale,
  useCampaignOrder,
  useVLCampaign,
} from '../../../utils/viralLoops'

const Position = ({ location }: { location: any }) => {
  const { t } = useTranslation(['waitlist'])

  const pathName = location.pathname

  let isBR: boolean = pathName.includes('br')

  let campaignLocale: CampaignLocale = isBR ? 'BR' : 'EN'

  const campaign = useVLCampaign(campaignLocale)
  const order = useCampaignOrder(campaign)

  return (
    <MainContainer>
      <UPArrow src={upArrow} />
      <UPArrowR src={upArrow} />
      <MainText>
        {t('youAre')}{' '}
        <span style={{ color: '#F2059F', fontWeight: 800 }}>#{order}</span>{' '}
        {t('toJoin')}
      </MainText>
      <Row>
        <LeftColumn campaignLocale={campaignLocale} />
        <RightColumn campaignLocale={campaignLocale} />
      </Row>
    </MainContainer>
  )
}

export default Position
