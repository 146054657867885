import styled from 'styled-components'

export const MainContainer = styled.div`
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  background: #f2059f;
  border-radius: 3.2em;
  padding: 6em 8em;
  margin-bottom: 6em;

  .d3-btn {
    border: 2px solid #000;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      150ms cubic-bezier(0, 0, 0.58, 1);

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000;
      border: 1px solid #000;
      border-radius: 4em;
      transform: translate3d(0, 0.7em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      transform: translate(0, 0.25em);
      &::before {
        transform: translate3d(0, 0.5em, -1em);
      }
    }
    &:active {
      transform: translate(0em, 0.75em);
      &::before {
        transform: translate3d(0, 0, -1em);
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 6em 1em;
  }
`

export const MainText = styled.div`
  text-align: center;
  font-weight: 900;
  font-size: 40px;
  line-height: 44px;
  color: #f8f7f8;
  margin-bottom: 1.6em;

  @media only screen and (max-width: 600px) {
    margin-bottom: 4.4em;
  }
`

export const LinkRow = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  max-width: 780px;
  margin: 0 auto;
  margin-bottom: 2em;
  gap: 2em;

  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const Link = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border: 2px solid #000;
  border-radius: 56px;
  max-width: 540px;
  width: 100%;
  padding: 0.6em 1em;
  font-weight: 500;
  font-size: 22px;
  white-space: pre;
  overflow-x: scroll;
  color: rgba(0, 0, 0, 0.699);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  transform: translate3d(0px, -12px, 0px);

  &::before {
    content: ' ';
    width: 15em;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 1000px) {
    min-width: 100%;
  }
`

export const CopyBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background: #ffff3e;
  border: 2px solid #000;
  border-radius: 56px;
  max-width: 216px;
  padding: 0.6em 2em;
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
  white-space: pre;

  @media only screen and (max-width: 1000px) {
    min-width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
`

export const SocialRow = styled.div`
  display: flex;
  max-width: 900px;
  margin: 0 auto;
  gap: 6em;
  padding-left: 7em;

  @media only screen and (max-width: 990px) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 600px) {
    padding: 0;
    flex-wrap: wrap;
  }
`

export const SocialsContainer = styled.div`
  display: flex;
  gap: 1.4em;
`

export const SocialLink = styled.a`
  background: #f8f7f8;
  width: 4.4em;
  height: 4.4em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.6em;
  font-weight: 800;
  border: 2px solid #000;

  @media only screen and (max-width: 600px) {
    width: 3.4em;
    height: 3.4em;
  }
`

export const LinkIcon = styled.img`
  width: 60%;
`

export const SmallText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  color: #f8f7f8;

  @media only screen and (max-width: 990px) {
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    text-align: center;
    margin-bottom: 4.4em;
  }
`

export const LeftArrowGroup = styled.img`
  position: absolute;
  top: -5px;
  left: -180px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

export const RightArrowGroup = styled.img`
  position: absolute;
  top: -35px;
  right: -160px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

export const OuterLayer = styled.div`
  background-color: #000;
  border-radius: 3.2em;
  margin-bottom: 0.2em;
  transform: translate3d(0px, 12px, 0px);

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
