import styled from 'styled-components'

export const MainContainer = styled.div`
  position: relative;
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  padding: 4em 6em;
  background-color: #ffffff;
  border: 2px solid #000;
  border-bottom: 12px solid #000;
  border-radius: 3.2em;
  margin-bottom: 10em;

  @media only screen and (max-width: 600px) {
    padding: 6em 1em;
  }
`

export const MainText = styled.div`
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000;
  margin-bottom: 0.8em;

  @media only screen and (max-width: 600px) {
    line-height: 105%;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    width: 100%;
  }
`

export const UPArrow = styled.img`
  position: absolute;
  top: 120px;
  left: -45px;

  @media only screen and (max-width: 1600px) {
    width: 6%;
  }

  @media only screen and (max-width: 600px) {
    width: 20%;
    top: 630px;
  }
`

export const UPArrowR = styled.img`
  position: absolute;
  top: 80px;
  right: -45px;
  transform: scaleX(-1);

  @media only screen and (max-width: 1600px) {
    width: 6%;
  }

  @media only screen and (max-width: 600px) {
    top: 100px;
    width: 20%;
  }
`
