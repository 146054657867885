import React from 'react'

import {
  LeftCoinGroup,
  MainContainer,
  PhoneImg,
  RightCoinGroup,
} from './styles'
import PhoneImage from '../../../images/waitlist/Phone.webp'
import coinGroup1 from '../../../images/waitlist/coinGroup.svg'
import coinGroup2 from '../../../images/waitlist/coinGroupRight.svg'
import coinGroupBr1 from '../../../images/waitlist/coinGroupBrL.svg'
import coinGroupBr2 from '../../../images/waitlist/coinGroupBrR.svg'

const Phone = ({ location }) => {
  const pathName = location.pathname

  let isBR: boolean = pathName.includes('br')

  return (
    <MainContainer>
      <LeftCoinGroup src={isBR ? coinGroupBr1 : coinGroup1} />
      <PhoneImg src={PhoneImage} />
      <RightCoinGroup src={isBR ? coinGroupBr2 : coinGroup2} />
    </MainContainer>
  )
}

export default Phone
