import styled from 'styled-components'

export const MainContainer = styled.div`
  position: relative;
  max-width: 1700px;
  margin: 0 auto;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;

  @media screen and (orientation: landscape) and (max-device-width: 900px) {
    height: 100vh;
    margin-bottom: 4em;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 14em;
    padding: 0em 2em;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: 1400px) {
    justify-content: center;
  }

  @media screen and (orientation: landscape) and (max-device-width: 900px) {
    height: 100vh;
  }

  @media only screen and (max-width: 900px) {
    height: 80vh;
  }
`

export const WelcomeText = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 6em;
`

export const HeroText = styled.div`
  font-size: 104px;
  font-weight: 700;
  line-height: 96px;
  color: #000;
  text-align: center;
  margin-bottom: 0.8em;
  white-space: pre-line;
  z-index: 5;

  @media only screen and (max-width: 900px) {
    font-size: 6.4em;
    line-height: 100%;
  }

  @media only screen and (max-width: 600px) {
    font-size: 6.2em;
    line-height: 105%;
  }
`

export const SecondaryText = styled.div`
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  color: #000;

  @media only screen and (max-width: 900px) {
    font-size: 2em;
    line-height: 100%;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2.4em;
    line-height: 105%;
  }
`

export const BuzzCoin = styled.img`
  position: absolute;
  top: 0;
  left: 10%;
  width: 11%;

  @media only screen and (max-width: 1350px) {
    top: 5%;
    width: 13%;
  }

  @media only screen and (max-width: 600px) {
    width: 38%;
    top: 50px;
    left: -20px;
  }
`

export const LeftArrow = styled.img`
  position: absolute;
  left: 0%;
  width: 8%;

  @media only screen and (max-width: 1350px) {
    left: -4%;
    width: 10%;
  }

  @media only screen and (max-width: 600px) {
    width: 38%;
    bottom: 185px;
    left: -110px;
  }
`

export const LeftNFT = styled.img`
  position: absolute;
  left: 13%;
  bottom: 8%;
  width: 7%;

  @media only screen and (max-width: 1350px) {
    bottom: 9%;
    width: 9%;
  }

  @media only screen and (max-width: 600px) {
    width: 23%;
    bottom: -110px;
    left: 50px;
  }
`

export const BuzzCoinRight = styled.img`
  position: absolute;
  right: 0%;
  width: 11%;
  transform: rotate(270deg);

  @media only screen and (max-width: 1200px) {
    width: 13%;
    right: -8%;
  }

  @media only screen and (max-width: 600px) {
    width: 38%;
    top: 260px;
    right: -95px;
  }
`

export const RightArrow = styled.img`
  position: absolute;
  right: 10%;
  width: 8%;
  bottom: 5%;

  @media only screen and (max-width: 1350px) {
    right: 2%;
    bottom: 7%;
    width: 10%;
  }

  @media only screen and (max-width: 600px) {
    width: 32%;
    bottom: -125px;
    right: 10px;
    rotate: 50deg;
  }
`

export const RightNFT = styled.img`
  position: absolute;
  top: 6%;
  right: 10%;
  width: 7%;

  @media only screen and (max-width: 1350px) {
    top: 9%;
    width: 9%;
  }

  @media only screen and (max-width: 600px) {
    width: 23%;
    top: 55px;
    right: 10px;
  }
`

export const LinePattern = styled.img`
  position: absolute;
  bottom: 0;
`
