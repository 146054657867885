import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  HeaderContainer,
  HeroText,
  MainContainer,
  SecondaryText,
  WelcomeText,
  LeftArrow,
  RightArrow,
  BuzzCoin,
  LeftNFT,
  RightNFT,
  BuzzCoinRight,
  LinePattern,
} from './styles'
import wave from '../../../images/waitlist/wave.svg'
import {
  buzzCoin,
  leftArrow,
  rightArrow,
  leftNFT,
  rightNFT,
} from '../../../images/waitlist/hero'
import { linePattern } from '../../../images/invited/hero'

const Hero = () => {
  const { t } = useTranslation(['waitlist'])

  return (
    <MainContainer>
      <BuzzCoin src={buzzCoin} />
      <LeftArrow src={leftArrow} />
      <LeftNFT src={leftNFT} />
      <BuzzCoinRight src={buzzCoin} />
      <RightArrow src={rightArrow} />
      <RightNFT src={rightNFT} />
      <HeaderContainer>
        <WelcomeText>
          {t('welcome')} <img src={wave} />
        </WelcomeText>
        <HeroText>
          {t('heroText1')}
          <span style={{ color: '#F2059F' }}> {t('friends')}</span> <br />
          {t('heroText2')}
        </HeroText>
        <SecondaryText>
          {t('heroSecondary1')} <span style={{ fontWeight: 700 }}>Buzz</span>{' '}
          {t('heroSecondary2')}
        </SecondaryText>
      </HeaderContainer>
      <LinePattern src={linePattern} />
    </MainContainer>
  )
}

export default Hero
