import styled from 'styled-components'

export const MainContainer = styled.div`
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 34em;

  @media only screen and (min-device-width: 375px) and (max-device-width: 860px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    width: 60%;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 14em;
    width: 90%;
  }
`

export const RewardsHeader = styled.div`
  width: 100%;
  max-width: 1400px;
  border-radius: 1em;
  background: #ffff3e;
  border: 3px solid #000000;
  border-bottom: 12px solid #000;
  padding: 1.4em;
  text-align: center;
  font-weight: 900;
  font-size: 32px;
  margin-bottom: -0.3em;

  @media only screen and (max-width: 900px) {
    padding: 0.6em;
  }
`

export const RewardsContainer = styled.div`
  display: flex;

  .main {
    scale: 1.08;
  }

  .main-header {
    font-weight: 900;
    background: #f2059f;
  }

  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;

    .main {
      scale: 1;
      order: -1;
    }
  }
`

export const RewardsCard = styled.div`
  flex: 1;

  @media only screen and (max-width: 900px) {
    min-width: 100%;
  }
`

export const CardHeader = styled.div`
  background: #d5048c;
  border-radius: 0.6em;
  border: 2px solid #000;
  border-bottom: 12px solid #000;
  padding: 0.6em;
  text-align: center;
  font-weight: 600;
  font-size: 56px;
  color: #fffff0;
  margin-bottom: -0.25em;
  white-space: pre;

  @media only screen and (max-width: 1140px) {
    white-space: normal;
    padding: 0.5em 0.8em;
  }

  @media only screen and (max-width: 900px) {
    padding: 0.8em;
    font-size: 4.2em;
  }
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 3px solid #000000;
  border-bottom: 12px solid #000000;
  border-radius: 1.8em;
  padding: 5em;
  flex: 1;

  .cross {
    color: #cecacc;
    text-decoration: line-through;
    font-weight: 500;
  }
`

export const CardBodyText = styled.div`
  font-weight: 900;
  font-size: 32px;
  line-height: 105%;
  color: #000;
  text-align: center;
  margin-bottom: 1em;
`

export const DiscountBadge = styled.div`
  text-align: center;
  width: 3em;
  background: #ffff3e;
  border: 2px solid #000;
  border-bottom: 6px solid #000;
  border-radius: 3.4em;
  font-weight: 900;
  font-size: 46px;
  transform: rotate(-2.68deg);
  margin-bottom: 0.8em;
`

export const DollarBadge = styled.img`
  position: absolute;
  top: 180px;
  right: -100px;
  z-index: 5;

  @media only screen and (max-width: 600px) {
    width: 45%;
    top: 1020px;
    right: -80px;
  }
`

export const DollarBadgeB = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 65%);
  z-index: 5;

  @media only screen and (max-width: 600px) {
    width: 45%;
    left: 0;
    bottom: 220px;
  }
`

export const StarBadge = styled.img`
  position: absolute;
  top: 30px;
  left: -100px;
  z-index: 5;

  @media only screen and (max-width: 600px) {
    width: 45%;
    top: 130px;
    left: -80px;
  }
`

export const SmallText = styled.div`
  margin-top: 1em;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #474345c1;
  border-top: 1px solid #000;
  padding-top: 1.2em;
`
