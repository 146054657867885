import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout/Layout'
import NavBar from '../fragments/waitlist/NavBar/NavBar'
import Hero from '../fragments/waitlist/Hero/Hero'
import Position from '../fragments/waitlist/Position/Position'
import Link from '../fragments/waitlist/Link/Link'
import Phone from '../fragments/waitlist/Phone/Phone'
import Footer from '../components/Footer/Footer'
import Rewards from '../fragments/waitlist/Rewards/Rewards'

const Waitlist = ({ location }) => {
  return (
    <>
      <NavBar />
      <Hero />
      <Position location={location} />
      <Link location={location} />
      <Rewards />
      <Phone location={location} />
      <Footer />
    </>
  )
}

const LayoutContainer = props => {
  return (
    <>
      <Layout isWaitlist>
        <Waitlist {...props} />
      </Layout>
    </>
  )
}

export default LayoutContainer

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
