import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  LinkRow,
  MainContainer,
  MainText,
  Link as CopyLink,
  CopyBtn,
  SocialsContainer,
  SocialLink,
  SocialRow,
  SmallText,
  LeftArrowGroup,
  RightArrowGroup,
  LinkIcon,
  OuterLayer,
} from './styles'

import {
  leftArrow,
  rightArrow,
  facebook,
  mail,
  twitter,
  messenger,
  whatsApp,
} from '../../../images/waitlist/Link'
import { CampaignLocale, useVLCampaign } from '../../../utils/viralLoops'

const Link = ({ location }: { location: any }) => {
  const { t } = useTranslation(['waitlist'])

  const [copied, setCopied] = useState(false)

  const pathName = location.pathname

  let isBR: boolean = pathName.includes('br')

  let campaignLocale: CampaignLocale = isBR ? 'BR' : 'EN'
  const campaign = useVLCampaign(campaignLocale)
  const URLs = campaign?.getUser()?.referralUrls
  const sharingOptions = campaign?.info.widgets.embedForm.sharing.sharingOptions
  const campaignTweets: string[] = Object.values(
    sharingOptions?.twitter.tweets ?? {}
  )
  const tweet = campaignTweets.length > 0 ? campaignTweets[0] : t('twitterText')

  const copyHandler = () => {
    if (!URLs) return

    navigator.clipboard.writeText(`${URLs.referralUrl}`)
    navigator.share?.({ url: URLs.referralUrl })

    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <MainContainer>
      <MainText>{t('linkMain')}</MainText>
      <LinkRow>
        <OuterLayer>
          <CopyLink>{URLs?.referralUrl}</CopyLink>
        </OuterLayer>
        <CopyBtn className="d3-btn" onClick={copyHandler}>
          {copied ? 'Copied!' : `${t('copy')} Link`}
        </CopyBtn>
        <LeftArrowGroup src={leftArrow} />
        <RightArrowGroup src={rightArrow} />
      </LinkRow>
      <SocialRow>
        <SocialsContainer>
          <SocialLink
            className="d3-btn"
            as="a"
            target="_blank"
            rel="noreferrer noopener"
            href={`https://www.facebook.com/sharer/sharer.php?u=${URLs?.facebookRefUrl}`}
          >
            <LinkIcon src={facebook} />
          </SocialLink>
          {/* <SocialLink
            as="a"
            target="_blank"
            rel="noreferrer noopener"
            href={` fb-messenger://share/?link=pageurl&app_id=******`}
          >
            <LinkIcon src={messenger} />
          </SocialLink> */}
          <SocialLink
            className="d3-btn"
            as="a"
            target="_blank"
            rel="noreferrer noopener"
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
              tweet
            )}&url=${encodeURIComponent(URLs?.twitterRefUrl)}&via=Buzz`}
          >
            <LinkIcon src={twitter} />
          </SocialLink>

          <SocialLink
            className="d3-btn"
            target="_blank"
            title="Share by Email"
            href={`mailto:?&subject=${t('mailSubject')} &body=${t(
              'mailText'
            )} ${URLs?.referralUrl}`}
          >
            <LinkIcon src={mail} />
          </SocialLink>
          <SocialLink
            className="d3-btn"
            href={`whatsapp://send?text=${t('whatsAppText')} ${
              URLs?.whatsappRefUrl
            } `}
            data-action="share/whatsapp/share"
            target="_blank"
          >
            <LinkIcon src={whatsApp} />
          </SocialLink>
        </SocialsContainer>
        <SmallText>{t('copyText')}</SmallText>
      </SocialRow>
    </MainContainer>
  )
}

export default Link
