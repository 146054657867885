import styled from 'styled-components'

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 0;
  max-width: 1700px;
  width: 95%;
  margin: 0 auto;

  .btn {
    color: #000;
    text-transform: uppercase;
    background: #ffff3e;
    border-radius: 4em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      150ms cubic-bezier(0, 0, 0.58, 1);

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000;
      border: 1px solid #000;
      border-radius: 4em;
      transform: translate3d(0, 0.7em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      background: #ffff3e;
      transform: translate(0, 0.25em);
      &::before {
        transform: translate3d(0, 0.5em, -1em);
      }
    }
    &:active {
      background: #ffff3e;
      transform: translate(0em, 0.75em);
      &::before {
        transform: translate3d(0, 0, -1em);
      }
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 2.6em;
  z-index: 5;

  @media only screen and (max-width: 600px) {
    gap: 0em;
  }
`

export const LinePattern = styled.img`
  position: absolute;
  top: 0px;
  z-index: -1;

  @media only screen and (max-width: 600px) {
    left: -220px;
  }
`
