import styled from 'styled-components'

export const LeftColumn = styled.div`
  display: flex;
  width: 48%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin-bottom: 2em;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    flex-wrap: wrap;
  }
`

export const PositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.2em;

  @media only screen and (max-width: 600px) {
    margin-bottom: 3em;
  }
`

export const InnerPositionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    gap: 2em;
  }
`

export const CurrentPositionContainer = styled.div`
  width: 48%;
  border: 3px solid #000;
  border-radius: 3.4em;
  padding: 1.6em;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const ReferralsContainer = styled.div`
  width: 48%;
  border: 3px solid #000;
  border-radius: 3.4em;
  padding: 1.6em;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const PositionCardTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #000;
`

export const PositionText = styled.div`
  font-weight: 900;
  font-size: 48px;
  color: #000;

  @media only screen and (max-width: 600px) {
    font-size: 4em;
  }
`

export const ReferralText = styled.div`
  font-weight: 900;
  font-size: 48px;
  color: #cecacc;
`

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  background: #e7e5e6;
  border: 3px solid #000;
  border-radius: 3.4em;
  padding: 2em 3em;
  gap: 3em;

  @media only screen and (max-width: 1400px) {
    .br {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    gap: 1.4em;
    padding: 2em 0.8em;
  }
`

export const DescriptionText = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.05em;

  @media only screen and (max-width: 600px) {
    font-size: 1.8em;
  }
`

export const AlertImg = styled.img`
  background-size: cover;
`
