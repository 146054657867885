import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { CampaignLocale, useVLCampaign } from '../../../../utils/viralLoops'
import {
  DownVector,
  RightCol,
  ScrollBtn,
  Waitlist,
  WaitlistContainer,
  WaitlistEntry,
  WaitlistHeading,
} from './styles'
import { downVector } from '../../../../images/waitlist/position'

const RightColumn = ({
  campaignLocale,
}: {
  campaignLocale: CampaignLocale
}) => {
  const { t } = useTranslation(['waitlist'])

  const campaign = useVLCampaign(campaignLocale)
  const leaderBoard = campaign?.getUser()?.leaderBoard

  const slideDown = () => {
    var slider = document.getElementById('waitlist')
    slider.scrollTop = slider.scrollTop + 100
  }

  return (
    <RightCol>
      <WaitlistContainer>
        <WaitlistHeading>{t('waitingList')}</WaitlistHeading>
        <Waitlist id="waitlist">
          {leaderBoard?.map(({ leadname }, index) => {
            return (
              <WaitlistEntry key={`${index}-${leadname}`}>
                <span style={{ fontWeight: 900 }}>{index + 1}</span> {leadname}
              </WaitlistEntry>
            )
          })}
        </Waitlist>
        <ScrollBtn id="waitlist" onClick={slideDown}>
          <DownVector src={downVector} />
        </ScrollBtn>
      </WaitlistContainer>
    </RightCol>
  )
}

export default RightColumn
