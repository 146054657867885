import React from 'react'

import {
  LeftColumn as Left,
  CurrentPositionContainer,
  DescriptionContainer,
  InnerPositionRow,
  PositionCardTitle,
  PositionContainer,
  PositionText,
  ReferralsContainer,
  ReferralText,
  DescriptionText,
  AlertImg,
} from './styles'
import alert from '../../../../images/waitlist/alert.svg'
import {
  CampaignLocale,
  useCampaignRankData,
  useVLCampaign,
} from '../../../../utils/viralLoops'

import { useTranslation } from 'gatsby-plugin-react-i18next'

const LeftColumn = ({ campaignLocale }: { campaignLocale: CampaignLocale }) => {
  const { t } = useTranslation(['waitlist'])

  const campaign = useVLCampaign(campaignLocale)
  const rankData = useCampaignRankData(campaign)

  return (
    <Left>
      <PositionContainer>
        <InnerPositionRow>
          <CurrentPositionContainer>
            <PositionCardTitle>{t('currentPosition')}</PositionCardTitle>
            <PositionText>#{rankData?.rank}</PositionText>
          </CurrentPositionContainer>
          <ReferralsContainer>
            <PositionCardTitle>{t('referrals')}</PositionCardTitle>
            <ReferralText>{rankData?.referralCountTotal}</ReferralText>
          </ReferralsContainer>
        </InnerPositionRow>
        <DescriptionContainer>
          <AlertImg src={alert} />
          <DescriptionText>
            {t('let')} {rankData?.suggestion.referrals} {t('friendsText')}{' '}
            <br className="br" />
            {t('climbText')} {rankData?.suggestion.newPosition}
          </DescriptionText>
        </DescriptionContainer>
      </PositionContainer>
    </Left>
  )
}

export default LeftColumn
