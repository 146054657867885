import React from 'react'

import LanguageSelector from '../../../components/LanguageSelecter/LanguageSelector'
import { ButtonsContainer, LinePattern, MainContainer } from './styles'
import { SocialButton, Svg } from '../../../components/Footer/styles'
import Twitter from '../../../images/footer/twitter.svg'
import Telegram from '../../images/footer/telegram.svg'
import Medium from '../../images/footer/medium.svg'
import Logo from '../../../images/waitlist/logo.svg'
import { heroLinePattern } from '../../../images/waitlist/hero'

const NavBar = () => {
  return (
    <MainContainer>
      <a
        href="/"
        style={{
          display: 'inline-block',
          cursor: 'pointer',
          zIndex: 5,
        }}
      >
        <img src={Logo} />
      </a>
      <LinePattern src={heroLinePattern} />
      <ButtonsContainer>
        <SocialButton
          className="btn"
          as="a"
          target="_blank"
          rel="noreferrer noopener"
          href="https://twitter.com/buzztheapp"
        >
          <Svg src={Twitter} />
        </SocialButton>
        <LanguageSelector color="#000000" />
      </ButtonsContainer>
    </MainContainer>
  )
}

export default NavBar
